<template>
  <div class="news" v-if="news.results && news.results.length">
    <div class="container">
      <div class="news-header">
        <h2>{{ $t('news') }}</h2>
        <nuxt-link :to="localePath('/news')">{{ $t('all_news') }}</nuxt-link>
      </div>
      <div class="news-items">
        <template v-for="item in news.results">
          <div class="item">
            <div class="item-text">
              <img preload v-if="item.image"
                :src="item.image.url | baseUrl"
                :width="item.image.width"
                :height="item.image.height"
                :alt="getNewsLocaleData(item).title"
                loading="lazy"
              />
              <span class="date hide-mobile">{{ item.date|formatDateNews }}</span>

              <span class="date hide-desktop">{{ item.date|formatDate }}</span>

              <div class="news-title">{{ getNewsLocaleData(item).title }}</div>
              <p></p>
              <div class="text hide-mobile">
                <span class="intro">{{ getNewsLocaleData(item).intro }}</span>
                <!--                <nuxt-link :to="localePath('/news/' + item.id)">{{ $t('learn_more') }}</nuxt-link>-->
              </div>
            </div>
            <div class="item-link">
              <nuxt-link v-if="!getNewsLocaleData(item).link_name" :to="localePath('/news/' + item.id)"
                         class="hide-mobile">{{
                  $t('learn_more')
                }}
              </nuxt-link>
              <nuxt-link v-else :to="localePath('/news/' + item.id)" class="hide-mobile">{{
                  getNewsLocaleData(item).link_name
                }}
              </nuxt-link>
              <nuxt-link :to="localePath('/news/' + item.id)" class="intro hide-desktop">{{
                  getNewsLocaleData(item).intro
                }}
              </nuxt-link>
            </div>
          </div>

<!--          <div class="item item-mobile">-->
<!--            <div class="item-text">-->
<!--              <img preload v-if="item.image" :src="item.image.url | baseUrl"-->
<!--                :width="item.image.width"-->
<!--                :height="item.image.height"-->
<!--                :alt="getNewsLocaleData(item).title"-->
<!--                loading="lazy"-->
<!--              />-->
<!--              <span class="date">{{ item.date|formatDate }}</span>-->
<!--              <h4>{{ getNewsLocaleData(item).title }}</h4>-->
<!--            </div>-->
<!--            <div class="item-link">-->
<!--              <nuxt-link :to="localePath('/news/' + item.id)" class="intro">{{ getNewsLocaleData(item).intro }}</nuxt-link>-->
<!--            </div>-->

<!--          </div>-->

        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {NewsItem} from '@/models/news';

export default {
  data() {
    return {
      news: {},
    }
  },
  async fetch() {
    this.news = await NewsItem.config({count_loading: true}).params({page_size: 3}).first();
  },
  methods: {
    getNewsLocaleData(newsItem) {
      return newsItem.news_item_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    }
  },
}
</script>
